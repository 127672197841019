import * as ko from "knockout";
import { TooltipOption } from 'bootstrap';


// Old bootstrap
// https://github.com/faulknercs/Knockstrap
// https://github.com/billpull/knockout-bootstrap/blob/master/src/knockout-bootstrap.js
// https://stackoverflow.com/questions/16875773/bootstraps-tooltip-not-working-with-knockout-bindings-w-fiddle

ko.bindingHandlers["tooltip"] = {
  'init': function (element, valueAccessor) {
      const $element = $(element);
  
      ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        if ($element.data('bs.tooltip')) {
            $element.tooltip('dispose');
        }
      });
  },
  'update': function (element, valueAccessor) {
      const $element = $(element);
      const options = ko.unwrap(valueAccessor());
      const optionsJson = <TooltipOption>ko.toJS(options);
  
      if(optionsJson && optionsJson.title === null) {
        optionsJson.title = '';
      } 

      let tooltipData = $element.data('bs.tooltip');
      if (!tooltipData) {
          $element.tooltip(optionsJson);
      } else {
          ko.utils.extend(tooltipData.config, optionsJson);
      }
    }
  };