import * as $ from "jquery";
import * as ko from "knockout";
import * as L from "leaflet";
import * as _ from "lodash";
import * as moment from "moment";
import "./ko-bootstrap";
import "../src/js/jquery.wait-overlay";
import "../src/js/jquery.autocomplete.min";
import "../src/js/jquery.scrollintoview.min";

// polyfill
if (!Object.values) Object.values = (o) => Object.keys(o).map((k) => o[k]);

function leftPad(value, length, padding) {
  let output = value + "";
  while (output.length < length) {
    output = padding + output;
  }
  return output;
}

function leftPadZero(number, length) {
  return leftPad(number, length, "0");
}

const isNumber = (value) => {
  if (typeof value !== "number") {
    return false;
  }
  if (value !== Number(value)) {
    return false;
  }
  if (value === Infinity || value === -Infinity) {
    return false;
  }
  return true;
};

function getHashParameters(hash) {
  return hash
    ? hash
        .replace(/(^\#)/, "")
        .split("&")
        .map(
          function (n, _, array) {
            return (
              (n = n.split("=")),
              (array[n[0]] = decodeURIComponent(n[1].replace(/\+/g, "%20"))),
              array
            );
          }.bind({})
        )[0]
    : {};
}

function Compare(x, y) {
  return x < y ? -1 : y < x ? 1 : 0;
}

function AlphanumComparer(s1, s2) {
  if (typeof s1 !== "string" || typeof s1 !== "string") return Compare(s1, s2);

  var result = 0;
  var i1 = 0,
    i2 = 0,
    z1 = 0,
    z2 = 0;
  while (i1 < s1.length && i2 < s2.length) {
    var start1 = i1;
    var start2 = i2;

    for (; i1 < s1.length && s1[i1] >= "0" && s1[i1] <= "9"; i1++);
    for (; i2 < s2.length && s2[i2] >= "0" && s2[i2] <= "9"; i2++);

    var alpha1 = start1 === i1;
    var alpha2 = start2 === i2;

    result = Compare(alpha1 ? s1[i1] : "0", alpha2 ? s2[i2] : "0"); // Num before Alpha ex: 123 < 'A'
    if (result !== 0) break;

    for (; start1 < s1.length && s1[start1] === "0"; start1++, z1++);
    for (; start2 < s2.length && s2[start2] === "0"; start2++, z2++);

    result = Compare(i1 - start1, i2 - start2); // Short number before long ex: 22 < 111
    if (result !== 0) break;

    while (result === 0 && start1 < i1 && start2 < i2)
      result = Compare(s1[start1++], s2[start2++]); // if equal length then compare left to right ex: 1211 < 1300
    if (result !== 0) break;

    result = Compare(z1, z2); // less total preceding zeroes ex: 00 < 000
    if (result !== 0) break;

    i1 += alpha1 ? 1 : 0;
    i2 += alpha2 ? 1 : 0;
  }

  if (result === 0) result = Compare(s1.length - i1, s2.length - i2); // '111' < '111_'

  return result;
}

(moment as any).duration.fn.units = {
  seconds: /s/,
  minutes: /m/,
  hours: /H/,
};

(moment as any).duration.fn.customFormatTokens = {
  MaybeTotalHours: {
    regex: /\[HHH:\]/g,
    value: function (mom) {
      return mom.asHours() > 1 ? Math.floor(mom.asHours()) + ":" : "";
    },
  },
  TotalHours: {
    regex: /HHH/g,
    value: function (mom) {
      return Math.floor(mom.asHours());
    },
  },
  MaybeTotalMinutes: {
    regex: /\[mmm:\]/g,
    value: function (mom) {
      return mom.asMinutes() > 1 ? Math.floor(mom.asMinutes()) + ":" : "";
    },
  },
  TotalMinutes: {
    regex: /mmm/g,
    value: function (mom) {
      return Math.floor(mom.asMinutes());
    },
  },
  Minutes: {
    regex: /mm/g,
    value: function (mom) {
      return leftPadZero(mom.minutes(), 2);
    },
  },
  MaybeTotalSeconds: {
    regex: /\[sss:\]/g,
    value: function (mom) {
      return mom.asMinutes() > 1 ? Math.floor(mom.asMinutes()) + ":" : "";
    },
  },
  TotalSeconds: {
    regex: /sss/g,
    value: function (mom) {
      return Math.floor(mom.asSeconds());
    },
  },
  Seconds: {
    regex: /ss/g,
    value: function (mom) {
      return leftPadZero(mom.seconds(), 2);
    },
  },
};

declare module "moment" {
  interface Duration {
    formatI4m(format: string): string;
  }
}

(moment as any).duration.fn.formatI4m = function (format: string): string {
  let lowestUnit = null;
  for (let tokenName in (moment as any).duration.fn.units) {
    let token = (moment as any).duration.fn.units[tokenName];
    if (token.test(format)) {
      lowestUnit = tokenName;
      break;
    }
  }

  let actualDuration = this;
  if (lowestUnit) {
    actualDuration = moment.duration(
      Math.round(this.as(lowestUnit)),
      lowestUnit
    );
  }

  let result = format;
  for (let tokenName in (moment as any).duration.fn.customFormatTokens) {
    let token = (moment as any).duration.fn.customFormatTokens[tokenName];
    if (token.regex.test(result)) {
      var replacement = token.value(actualDuration);
      result = result.replace(token.regex, replacement);
    }
  }
  return result;
};

interface ForecastTimeFormatInput {
  datetime: moment.Moment;
  duration: moment.Duration;
  isRealtime: boolean;
  isCancelled: boolean;
  isCongestion: boolean;
}
type ForecastTimeFormatResult = { success: boolean; time: string };
type ForecastTimeFormatFunction = (
  input: ForecastTimeFormatInput
) => ForecastTimeFormatResult;

class ForecastTimeFormatter {
  constructor(config: Configuration, model: Model) {
    this.config = config;
    this.model = model;
    this.parse(this.config);
  }

  config: Configuration;
  model: Model;
  invocations: ForecastTimeFormatFunction[] = [];

  settingsNow = { time: 30 };
  settingsRelative = { time: null };

  public reset() {
    this.invocations = [];
    this.settingsNow = { time: 30 };
    this.settingsRelative = { time: null };
  }

  public parse(config) {
    this.reset();
    let forecastConfigurations = config.formatForecastTime;

    //for (let c of configurations) {
    for (let index in forecastConfigurations) {
      let c = forecastConfigurations[index];
      let param = c;
      switch (c.type.toLowerCase()) {
        case "forecastnow":
          {
            let settings = this.settingsNow;
            if (param && param.time) {
              settings.time = moment.duration(param.time).asSeconds();
            }
            let lamda: ForecastTimeFormatFunction = (
              input: ForecastTimeFormatInput
            ): ForecastTimeFormatResult => {
              return this.tryNowTime(input);
            };
            this.invocations.push(lamda);
          }
          break;
        case "absolutetime":
          {
            const lamda: ForecastTimeFormatFunction = (
              input: ForecastTimeFormatInput
            ): ForecastTimeFormatResult => {
              return this.tryAbsoluteForecastTime(input);
            };
            this.invocations.push(lamda);
          }
          break;
        case "relativetime":
          {
            let settings = this.settingsRelative;
            if (param && param.time) {
              settings.time = moment.duration(param.time).asSeconds();
            }
            const lamda: ForecastTimeFormatFunction = (
              input: ForecastTimeFormatInput
            ): ForecastTimeFormatResult => {
              return this.tryRelativeForecastTime(input);
            };
            this.invocations.push(lamda);
          }
          break;
        default:
          let msg = "Unknown type: " + c.Type;
          console.error(msg);
          throw msg;
      }
    }
    return this.invocations;
  }

  public formatTime(input: ForecastTimeFormatInput): string {
    if (input.isCancelled) {
      return this.model.viewModel.textCancelled();
    }

    //if (input.isCongestion && this.model.isShowingCongestion) {
    //    return model.viewModel.textCongestionText();
    //}

    for (let index in this.invocations) {
      let invocation = this.invocations[index];
      var result = invocation(input);
      if (result && result.success) {
        return result.time;
      }
    }

    return "";
  }

  public absoluteTime(datetime: moment.Moment): string {
    var formattedTime = datetime.format(this.model.config.formatAbsoluteTime);
    return formattedTime;
  }

  public relativetime(duration: moment.Duration): string {
    //var result = Math.round(duration.asMinutes());
    //let formattedTime = (result | 0).toString() + ' ' + model.viewModel.textMin();
    //return formattedTime;
    let formattedTime = duration.formatI4m(
      this.model.config.formatRelativeTime
    );
    return formattedTime;
  }

  private tryNowTime(input: ForecastTimeFormatInput): ForecastTimeFormatResult {
    if (
      input.isRealtime &&
      this.settingsNow.time &&
      input.duration.asSeconds() <= this.settingsNow.time
    ) {
      return { success: true, time: this.model.viewModel.textNow() };
    }

    return { success: false, time: null };
  }

  private tryAbsoluteForecastTime(
    input: ForecastTimeFormatInput
  ): ForecastTimeFormatResult {
    if (input.datetime) {
      var formattedTime = this.absoluteForecastTime(input);
      return { success: true, time: formattedTime };
    }
    return { success: false, time: null };
  }

  private absoluteForecastTime(input: ForecastTimeFormatInput): string {
    let formattedTime = this.absoluteTime(input.datetime);
    if (!input.isRealtime && this.model.config.useNonForcastIndicator) {
      formattedTime = this.model.viewModel.textCa() + " " + formattedTime;
    }
    return formattedTime;
  }

  private tryRelativeForecastTime(
    input: ForecastTimeFormatInput
  ): ForecastTimeFormatResult {
    if (
      !this.settingsRelative.time ||
      input.duration.asSeconds() <= this.settingsRelative.time
    ) {
      let time = this.relativeForecastTime(input);
      return { success: true, time: time };
    }

    return { success: false, time: null };
  }

  private relativeForecastTime(input: ForecastTimeFormatInput): string {
    let formattedTime =
      this.relativetime(input.duration) + " " + this.model.viewModel.textMin();
    if (!input.isRealtime && this.model.config.useNonForcastIndicator) {
      formattedTime = this.model.viewModel.textCa() + " " + formattedTime;
    }
    return formattedTime;
  }
}

class Model {
  config: Configuration = null;
  viewModel: ViewModel = null;
  resourceModel: ResourceModel = null;
  translationModel: TranslationModel = null;
  bootstrap: Bootstrap = null;
  timeFormatter: ForecastTimeFormatter = null;
}

class Appearance {
  constructor() {
    this.clear();
  }
  background = ko.observable("");
  foreground = ko.observable("");
  fontStyle = ko.observable("");

  update(api: any) {
    this.background(api.background);
    this.foreground(api.foreground);
    this.fontStyle(api.fontStyle);
  }

  clear() {
    this.background("");
    this.foreground("");
    this.fontStyle("");
  }
}

class Location {
  latitude = ko.observable(null);
  longitude = ko.observable(null);

  update(api: any) {
    this.latitude(api.lat);
    this.longitude(api.lon);
  }

  toLeaflet() {
    let latLng = L.latLng(this.latitude(), this.longitude());
    return latLng;
  }
}

class PositionMap {
  timestamp = ko.observable<moment.Moment>(null);
  heading = ko.observable<number>(null);
  location = ko.observable<Location>(null);

  update(api: any) {
    this.timestamp(api.timestamp);
    this.heading(api.heading);
    this.location(null);
    if (api.location) {
      this.location(new Location());
      this.location().update(api.location);
    }
  }
}

class VehiclePosition extends PositionMap {
  id = ko.observable<string>(null);
  displayName = ko.observable<string>(null);
  classes = ko.observable<string[]>([]);

  update(api: any) {
    super.update(api);
    this.id(api.id);
    this.displayName(api.displayName);
    this.classes(api.classes);
  }
}

class Line {
  id = ko.observable(0);
  text = ko.observable("");

  update(api: any) {
    this.id(api.id);
    this.text(api.text);
  }
}

class StopArea {
  id = ko.observable(0);
  text = ko.observable("");
  location: ko.Observable<Location> = ko.observable(null);

  update(api: any) {
    this.id(api.id);
    this.text(api.text);
    this.location(null);
    if (api.location) {
      let loc = new Location();
      loc.update(api.location);
      this.location(loc);
    }
  }
}

class Direction {
  id = ko.observable(0);
  text = ko.observable("");

  update(api: any) {
    this.id(api.id);
    this.text(api.text);
  }
}

class Message {
  id: ko.Observable<number> = ko.observable(0);
  text = ko.observable("");
  lines = ko.observableArray<MessageLine>([]);
  AffectedCalls = ko.observableArray<string>([]);
  isDisturbance = ko.observable(false);
  isInfo = ko.observable(false);
  getIconClass = ko.pureComputed(() => {
    return this.isDisturbance()
      ? "icon-exclamation"
      : "icon-image-exclam-minor";
  });
  getDisturbanceColors = ko.pureComputed(() => {
    return this.isDisturbance() ? "disturbance-color" : "";
  });

  update(api: any) {
    this.id(api.id);
    this.text(api.text);
    this.isDisturbance(api.isDisturbance);
    this.isInfo(api.isInfo);

    let affectedCallIds = [];
    api.affectedCalls.forEach((element) => {
      affectedCallIds.push(element);
    });
    this.AffectedCalls(affectedCallIds);

    const lines = [];
    api.lines.forEach((element) => {
      let line = new MessageLine();
      line.lineId(element.lineId);
      line.bgLineColor(element.bgLineColor);
      line.fgLineColor(element.fgLineColor);
      line.lineColor(element.lineColor);
      line.lineName(element.lineName);
      lines.push(line);
    });

    this.lines(lines);
  }
}

class MessageLine {
  lineId = ko.observable(0);
  lineName = ko.observable("");

  bgLineColor = ko.observable("");
  bgLineColorHex = ko.pureComputed(() => {
    return "#" + this.bgLineColor();
  });
  fgLineColor = ko.observable("");
  fgLineColorHex = ko.pureComputed(() => {
    return "#" + this.fgLineColor();
  });
  lineColor = ko.observable("");
  lineColorHex = ko.pureComputed(() => {
    return "#" + this.lineColor();
  });
}

enum JourneyType {
  Undefined = "Undefined",
  Normal = "Normal",
  Extra = "Extra",
}

// enum ForecastQuality {
//     UNDEFINED = 'UNDEFINED',
//     TIMETABLE = 'TIMETABLE',
//     REALTIME = 'REALTIME',
//     CANCELLED = 'CANCELLED'
// };

class Forecast {
  constructor(call: Call) {
    this.call = call;
  }

  call: Call;

  PlannedTime = ko.observable(moment(0));
  ForecastTime = ko.observable(moment(0));

  ForecastType = ko.observable("");
  Quality = ko.observable("");
  Attributes = ko.observableArray<string>([]);

  JourneyType = ko.observable(JourneyType.Undefined);
  VehicleType = ko.observable("");
  Designation = ko.observable("");
  OccupancyPercent = ko.observable(null);

  update(api: any) {
    this.PlannedTime(moment(api.forecastTime));
    this.ForecastTime(moment(api.plannedTime));
    this.Quality(api.quality);
    this.Attributes(api.attributes);

    let journeyType = JourneyType.Undefined;
    if (typeof api.journeyType === "string") {
      switch (api.journeyType.toLowerCase()) {
        case "extra":
        case "reinforcement":
          journeyType = JourneyType.Extra;
          break;
        default:
          journeyType = JourneyType.Normal;
      }
    }
    this.JourneyType(journeyType);
    this.VehicleType(api.vehicleType);
    this.Designation(api.designation);
    this.OccupancyPercent(
      isNumber(api.occupancyPercent) ? api.occupancyPercent : null
    );
  }
}

class Call {
  constructor(row: Row, model: Model) {
    this.row = row;
    this.model = model;
  }

  row: Row;
  model: Model;
  Key = ko.observable("");

  // current viewed/focused forecast - now always departure, future maybe arrival
  CurrentForecast = ko.pureComputed(() => {
    return this.Departure();
  });
  Arrival = ko.observable<Forecast>(null);
  Departure = ko.observable<Forecast>(null);

  update(api: any) {
    this.Key(api.key);

    if (!api.arrival) {
      this.Arrival(null);
    } else {
      let fc = this.Arrival();
      if (fc == null) {
        fc = new Forecast(this);
        fc.ForecastType("arrival");
        this.Arrival(fc);
      }
      fc.update(api.arrival);
    }

    if (!api.departure) {
      this.Departure(null);
    } else {
      let fc = this.Departure();
      if (fc == null) {
        fc = new Forecast(this);
        fc.ForecastType("departure");
        this.Departure(fc);
      }
      fc.update(api.departure);
    }
  }
}

class Row {
  constructor(id: number, model: Model) {
    this.Id = id;
    this.model = model;
  }

  Id: number;
  model: Model;

  Calls = ko.observableArray<Call>([]);

  NextCall = this.GetCall(0);
  AfterCall = this.GetCall(1);

  getRowDisturbances = ko.pureComputed(() => {
    let messages = [];
    const NextCall = this.GetCall(0);
    const AfterCall = this.GetCall(1);
    if (!NextCall && !AfterCall) {
      return messages;
    }

    messages = this.model.viewModel
      .messages()
      .filter((x) => x.AffectedCalls().length > 0);
 
    messages = messages.filter(
      (x) =>
        x.AffectedCalls().includes(NextCall()?.Key()) ||
        x.AffectedCalls().includes(AfterCall()?.Key())
    );


    const TotalIndexes = this.model.viewModel
      .calls()
      .reduce((total, x) => total + x.Calls().length, 0);

      if (TotalIndexes != 1) {
        messages = messages.filter(
          (x) => x.AffectedCalls().length !== TotalIndexes
          );
        }

    return messages;
  });

  getDisturbanceColor = (callIndex) => {
    const call = this.GetCall(callIndex);
    if (!call) {
      return "";
    }
  
    const messages = this.model.viewModel
      .messages()
      .filter(
        (x) =>
          x.AffectedCalls().length > 0 &&
          x.AffectedCalls().includes(call()?.Key())
      );
  
    const TotalIndexes = this.model.viewModel
      .calls()
      .reduce((total, x) => total + x.Calls().length, 0);
  
    const allIndexesAffected = messages.every(message =>
      message.AffectedCalls().length === TotalIndexes
    );
  
    return messages.length > 0 && !allIndexesAffected
      ? "disturbance-color center-text-override rounded-corners bold"
      : "";
  };
  
  getNextDisturbanceColors = ko.pureComputed(() => {
    return this.getDisturbanceColor(0);
  });

  getAfterDisturbanceColors = ko.pureComputed(() => {
    return this.getDisturbanceColor(1);
  });

  public GetCall(index: number): ko.PureComputed<Call> {
    return ko.pureComputed(() => {
      return this.Calls()[index];
    }, this);
  }

  Next = ko.observable("");
  NextHhMm = ko.observable("");
  After = ko.observable("");
  AfterHhMm = ko.observable("");

  RowLabel = ko.pureComputed(() => {
    switch (this.model.config.rowLabel) {
      case "Line":
        return this.Line();
      case "Journey":
        return this.Journey();
    }
    return "";
  });

  CallId = ko.observable("");
  RouteId = ko.observable(0);
  JourneyId = ko.observable(0);
  Journey = ko.observable("");
  Line = ko.observable("");
  LineAppearance = ko.observable(new Appearance());
  Dest = ko.observable("");
  Subdest = ko.observable("");
  SubdestText = ko.pureComputed(() => {
    const call = this.NextCall();
    if (!call) {
      return this.Subdest();
    }
    const forecast = call.CurrentForecast();
    if (!forecast) {
      return this.Subdest();
    }

    const state = this.model.viewModel.frameStateMap.get(
      FrameSourceType.Destination
    );
    if (state == null) {
      return this.Subdest();
    }

    const frame = state.frame();
    if (frame == null) {
      return this.Subdest();
    }

    const frameType: DestinationFrameType =
      DestinationFrameTypeMap[frame.template.toLowerCase()];
    const isReinforcement = forecast.JourneyType() == JourneyType.Extra;
    let textReinforcement = null;
    if (isReinforcement) {
      textReinforcement = this.model.translationModel.getTranslation(
        "textReinforcement",
        { row: this }
      )();
    }

    switch (frameType) {
      case DestinationFrameType.Reinforcement:
        if (isReinforcement) {
          return textReinforcement;
        }
      case DestinationFrameType.Default:
      default:
        return this.Subdest() || textReinforcement;
        break;
    }
  });

  NextWheelchairAdapted = ko.observable(false);
  AfterWheelchairAdapted = ko.observable(false);

  NextRealtime = ko.observable(false);
  NextAttr = ko.computed(() => {
    return this.NextWheelchairAdapted() || this.NextRealtime();
  });

  NextAttrImgSrc = ko.pureComputed(() => {
    if (this.NextWheelchairAdapted()) {
      return this.model.viewModel.iconWheelchair();
    } else if (this.NextRealtime()) {
      return this.model.viewModel.iconRealtime();
    }
    return this.model.viewModel.iconHash();
  });

  NextCss = ko.pureComputed(() => {
    let qualityCss = this.NextForecastQualityCss();
    return qualityCss.trim();
  });

  NextForecastQualityCss = ko.pureComputed(() => {
    let css = "";
    if (this.IsNextCancelled()) {
      css = "fc-quality-cancelled";
    } else if (this.NextRealtime()) {
      css = "fc-quality-realtime";
    } else if (this.Next()) {
      css = "fc-quality-timetable";
    }
    if (!css) {
      css = "fc-quality-nodata";
    }

    return css;
  });

  NextDeparture = ko.observable(moment(0));
  IsNextCancelled = ko.observable(false);

  IsAfterRealtime = ko.observable(false);
  AfterDeparture = ko.observable(null);
  CreatedTime = ko.observable(moment(0));
  IsAfterCancelled = ko.observable(false);

  AfterAttr = ko.computed(() => {
    return this.AfterWheelchairAdapted() || this.IsAfterRealtime();
  });

  AfterAttrImgSrc = ko.pureComputed(() => {
    if (this.AfterWheelchairAdapted()) {
      return this.model.viewModel.iconWheelchair();
    } else if (this.IsAfterRealtime()) {
      return this.model.viewModel.iconRealtime();
    }
    return this.model.viewModel.iconHash();
  });

  AfterCss = ko.pureComputed(() => {
    let qualityCss = this.AfterForecastQualityCss();
    return qualityCss.trim();
  });

  AfterForecastQualityCss = ko.pureComputed(() => {
    let css = "";
    if (this.IsAfterCancelled()) {
      css = "fc-quality-cancelled";
    } else if (this.IsAfterRealtime()) {
      css = "fc-quality-realtime";
    } else if (this.After()) {
      css = "fc-quality-timetable";
    }
    if (!css) {
      css = "fc-quality-nodata";
    }

    return css;
  });

  private getOccupancyPercentage(callType: "NextCall" | "AfterCall") {
    const call = this[callType]();
    if (!call) {
      return null;
    }
    const forecast = call.CurrentForecast();
    if (!forecast) {
      return null;
    }
    return forecast.OccupancyPercent();
  }

  // Utility function to get occupancy configuration based on the percentage
  private getOccupancyConfiguration(occupancy: number) {
    if (!isNumber(occupancy)) {
      return null;
    }

    let level: OccupancyLevel = null;
    for (let l of this.model.config.occupancy.levels) {
      let match = true;
      if (isNumber(l.from)) {
        match = match && l.from <= occupancy;
      }
      if (isNumber(l.to)) {
        match = match && occupancy <= l.to;
      }
      if (match) {
        level = l;
        break;
      }
    }
    return level;
  }

  // Utility function to get image or alt text source based on configuration
  private getResourceSrc(
    configKey: string,
    resourceType: "imageKey" | "labelKey"
  ) {
    const config = this[configKey]();
    if (!config) {
      return null;
    }

    if (resourceType === "imageKey") {
      return this.model.resourceModel.getResource(config.imageKey)();
    } else if (resourceType === "labelKey") {
      return this.model.translationModel.getTranslation(config.labelKey)();
    }
  }

  // Computed observables for occupancy percentages
  OccupancyPercentageNext = ko.pureComputed(() =>
    this.getOccupancyPercentage("NextCall")
  );
  OccupancyPercentageAfter = ko.pureComputed(() =>
    this.getOccupancyPercentage("AfterCall")
  );

  // Computed observables for occupancy configurations
  OccupancyConfiguration = ko.pureComputed(() =>
    this.getOccupancyConfiguration(this.OccupancyPercentageNext())
  );
  OccupancyConfigurationAfter = ko.pureComputed(() =>
    this.getOccupancyConfiguration(this.OccupancyPercentageAfter())
  );

  // Computed observables for image sources
  OccupancyImgSrc = ko.pureComputed(() =>
    this.getResourceSrc("OccupancyConfiguration", "imageKey")
  );
  OccupancyAfterImgSrc = ko.pureComputed(() =>
    this.getResourceSrc("OccupancyConfigurationAfter", "imageKey")
  );

  // Computed observables for showing occupancy images
  ShowOccupancy = ko.pureComputed(() => !!this.OccupancyImgSrc());
  ShowOccupancyAfter = ko.pureComputed(() => !!this.OccupancyAfterImgSrc());

  // Computed observables for image alt texts
  OccupancyImgAlt = ko.pureComputed(() =>
    this.getResourceSrc("OccupancyConfiguration", "labelKey")
  );
  OccupancyAfterImgAlt = ko.pureComputed(() =>
    this.getResourceSrc("OccupancyConfigurationAfter", "labelKey")
  );

  OccupancyTooltipTitle = ko.computed<(this: Element) => string | Element>(
    () => {
      if (!this.ShowOccupancy()) return null;
      if (this.model.viewModel.size() !== SizeMode.Normal) {
        return null;
      }

      const creator = function (this: Element): string | Element {
        // const $element = $(this);
        // const tooltipData = $element.data("bs.tooltip");
        // console.log(tooltipData);
        const data = <Row>ko.dataFor(this);
        if (!data) return null;
        return data.model.viewModel.tooltip(this);
      };

      return creator;
    }
  );

  IsDirty = ko.observable(false);
  IsExpanded = ko.observable(false);
  StopAreaNumber = ko.observable("");
  NextStopAreaNumber = ko.observable("");
}

class Bootstrap {
  configLoaded = false;
  pageLoaded = false;
  profileLoaded = false;
  themeLoaded = false;

  callbacks: { (b: Bootstrap): boolean }[] = [];

  onUpdate() {
    for (let i = 0; i < this.callbacks.length; i++) {
      if (this.callbacks[i](this)) {
        this.callbacks.splice(i--, 1);
      }
    }

    if (this.callbacks.length === 0) {
      console.log("startup complete");
    }
  }

  addOnUpdate(callback: { (b: Bootstrap): boolean }) {
    this.callbacks.push(callback);
    this.onUpdate();
  }
}

class Configuration {
  api: ConfigurationApi = new ConfigurationApi();
  locale: any = null;
  formatClock: string = "";
  am_pm: boolean = true;
  useNonForcastIndicator: boolean = true;
  formatAbsoluteTime: string = "";
  formatRelativeTime: string = "";
  formatForecastTime: string[] = [];

  resultTable: ConfigurationResultTable = new ConfigurationResultTable();
  map: ConfigurationMap = new ConfigurationMap();
  frames: ConfigurationFrames = new ConfigurationFrames();
  occupancy: ConfigurationOccupancy = new ConfigurationOccupancy();

  rowGrouping: any[] = []; // How to group calls into rows
  rowOrder: any[] = []; // How to order rows
  rowData: any[] = []; // Additional row data needed

  rowLabel: string = "Line";
}

class ConfigurationApi {
  baseUri: string = "";
}

class ConfigurationMap {
  centerLat: number = 0;
  centerLon: number = 0;
  // same defaults as in default.json
  maxCount: number = 100;
  maxDistance: number = 750;
  zoom: number = null;
  minZoom: number = null;
  tilesUrl: string = "";
  tilesOptions: any = {};
}

class ConfigurationResultTable {
  showOccupancy = ko.observable(true);
  showExpander = ko.observable(true);
  showStopAreaNumber = ko.observable(false);
}

class ConfigurationFrames {
  frameMap = new Map<FrameSourceType, Frame[]>();

  static parse(config: any): ConfigurationFrames {
    const result = new ConfigurationFrames();
    if (!config) {
      return result;
    }

    if (config.destination) {
      const frames = [];
      let index = 0;
      for (let c of config.destination) {
        var f = Frame.parse(c);
        f.index = index++;
        frames.push(f);
      }
      result.frameMap.set(FrameSourceType.Destination, frames);
    }

    return result;
  }
}

class ConfigurationOccupancy {
  levels: OccupancyLevel[] = [];

  static parse(config: any): ConfigurationOccupancy {
    const result = new ConfigurationOccupancy();
    if (!config) {
      return result;
    }

    if (config.levels) {
      const levels = [];
      let index = 0;
      for (let c of config.levels) {
        var l = OccupancyLevel.parse(c);
        l.index = index++;
        levels.push(l);
      }
      result.levels = levels;
    }

    return result;
  }
}

class OccupancyLevel {
  index: number = 1;
  from: number = null;
  to: number = null;
  imageKey: string = "";
  labelKey: string = "";
  descriptionKey: string = "";

  static parse(config: any): OccupancyLevel {
    if (!config) {
      return null;
    }

    const occupancy = new OccupancyLevel();
    occupancy.from = config.from;
    occupancy.to = config.to;
    occupancy.imageKey = config.image;
    occupancy.labelKey = config.label;
    occupancy.descriptionKey = config.description;
    return occupancy;
  }
}

class Frame {
  index: number = 1;
  seconds: number = 1;
  template: string = "default";

  static parse(config: any): Frame {
    if (!config) {
      return null;
    }

    const frame = new Frame();
    frame.seconds = config.seconds;
    frame.template = config.template;
    return frame;
  }
}

enum VehicleMarkerIconType {
  Normal,
  Detailed,
}

class VehicleLocationMarker {
  id: string = "";
  title: string = "";
  latlng: L.LatLng = null;
  marker: L.Marker = null;
  iconDetailed: L.DivIcon = null;
  iconNormal: L.Icon = null;
  vehicleDiv: HTMLDivElement;
  vehicleImg: HTMLImageElement;
  vehicleArrowImg: HTMLImageElement;
  iconType = VehicleMarkerIconType.Normal;
  heading: number | null = null;

  public updatePosition(position: PositionMap) {
    this.latlng = position.location().toLeaflet();
    this.marker.setLatLng(this.latlng);
  }

  public updateHeading(position: PositionMap) {
    if (position && position.heading() != null) {
      this.heading = this.rotateHeading(this.heading, position.heading());
      this.vehicleArrowImg.style.display = "block";
      this.vehicleArrowImg.style.transform =
        "rotate(" + Math.round(this.heading) + "deg)";
    } else {
      this.vehicleArrowImg.style.display = "none";
      this.heading = null;
    }
  }

  private rotateHeading(
    prevHeading: number | null,
    nextHeading: number | null
  ): number {
    let result = nextHeading;
    if (nextHeading == null || isNaN(nextHeading)) return result;
    nextHeading = Math.round(nextHeading);
    if (prevHeading == null || isNaN(prevHeading)) return result;

    let div = Math.floor(prevHeading / 360);
    let rem = prevHeading % 360;
    if (rem < 0) {
      rem += 360;
    }
    if (rem - nextHeading > 180) {
      result = (div + 1) * 360 + nextHeading;
    } else if (nextHeading - rem > 180) {
      result = (div - 1) * 360 + nextHeading;
    } else {
      result = (div + 0) * 360 + nextHeading;
    }
    return result;
  }
}

class MapRoute {
  polyline: L.Polyline = null;
  polylineDirection: L.PolylineDecorator = null;

  addTo(map: L.Map): void {
    this.polyline.addTo(map);
    this.polylineDirection.addTo(map);
  }

  removeFrom(map: L.Map): void {
    this.polyline.removeFrom(map);
    this.polylineDirection.removeFrom(map);
  }

  getBounds(): L.LatLngBounds {
    return this.polyline.getBounds();
  }
}

class SearchParameters {
  fromStopAreaQuery = ko.observable("");
  toStopAreaQuery = ko.observable("");
  lineId = ko.observable(0);
  directionId = ko.observable(0);
  callLineDest = ko.observable("");

  hasData = ko.pureComputed(() => {
    let data = false;
    data = data || !!this.fromStopAreaQuery();
    data = data || !!this.toStopAreaQuery();
    data = data || !!this.lineId();
    data = data || !!this.directionId();
    data = data || !!this.callLineDest();

    return !!data;
  });

  updateUri() {
    let params: any = {};
    if (this.fromStopAreaQuery()) {
      params.f = this.fromStopAreaQuery();
    }
    if (this.toStopAreaQuery()) {
      params.t = this.toStopAreaQuery();
    }
    if (this.lineId()) {
      params.l = this.lineId();
    }
    if (this.directionId()) {
      params.d = this.directionId();
    }
    if (this.callLineDest()) {
      params.c = this.callLineDest();
    }

    let path = "#" + $.param(params);

    //X $.pathchange.changeTo(path);
    window.history.pushState(null, null, path);
    $(window).trigger("pathchange");
  }

  setFromUri() {
    let params = getHashParameters(window.location.hash);

    if (params.hasOwnProperty("f")) {
      this.fromStopAreaQuery(params.f);
    }
    if (params.hasOwnProperty("t")) {
      this.toStopAreaQuery(params.t);
    }
    if (params.hasOwnProperty("l")) {
      this.lineId(params.l);
    }
    if (params.hasOwnProperty("d")) {
      this.directionId(params.d);
    }
    if (params.hasOwnProperty("c")) {
      this.callLineDest(params.c);
    }
  }
}

var CloseMapControl = L.Control.extend({
  onAdd(map: L.Map): HTMLElement {
    var button = L.DomUtil.create("a");
    button.textContent = "X";
    button.style.cursor = "pointer";
    return button;
  },
  onRemove(map: L.Map) {},
});

var closeMapControl = function (options) {
  return new (CloseMapControl as any)(options);
};

enum FrameSourceType {
  Destination,
}

enum DestinationFrameType {
  Default,
  Reinforcement,
}

// Lowercase map
const DestinationFrameTypeMap: { [lowercase: string]: DestinationFrameType } =
  Object.values(DestinationFrameType)
    .filter((value) => typeof value === "string")
    .reduce((map, key) => {
      map[key.toString().toLowerCase()] = DestinationFrameType[key];
      return map;
    }, {});

class ViewModelFrameState {
  startTick: number | null = null;
  frame = ko.observable<Frame>(null);
}

class ResourceModel {
  private resources = ko.observable<any>(null);

  public setSource(resources: any) {
    this.resources(resources);
  }

  public getResource(key: string, options: any = null): ko.PureComputed<any> {
    const resource = ko.pureComputed(() => {
      return this.internalGetTranslation(this.resources(), key, options);
    });
    return resource;
  }

  private internalGetTranslation(
    source: any,
    key: string,
    options: any
  ): string {
    let resource = null;
    if (!key) {
      return null;
    }

    if (!resource) {
      if (source[key]) {
        resource = source[key];
      }
    }

    return resource;
  }
}

class TranslationModel {
  private translations = ko.observable<any>(null);

  public setSource(translations: any) {
    this.translations(translations);
  }

  public getTranslation(
    translationKey: string,
    options: any = null
  ): ko.PureComputed<string> {
    const translation = ko.pureComputed(() => {
      return this.internalGetTranslation(
        this.translations(),
        translationKey,
        options
      );
    });
    return translation;
  }

  private internalGetTranslation(
    translationSource: any,
    translationKey: string,
    options: any
  ): string {
    let translation = null;
    if (!translationKey) {
      return null;
    }

    if (options) {
      if (
        !translation &&
        options.forecast &&
        options.forecast instanceof Forecast
      ) {
        const fc = <Forecast>options.forecast;
        if (translationSource.VehicleType) {
          let vehicleType = fc.VehicleType();
          if (
            translationSource.VehicleType[vehicleType] &&
            translationSource.VehicleType[vehicleType][translationKey]
          ) {
            translation =
              translationSource.VehicleType[vehicleType][translationKey];
          }
        }
      }

      if (!translation && options.row && options.row instanceof Row) {
        const row = <Row>options.row;
        if (translationSource.VehicleType) {
          let vehicleType = row.NextCall().CurrentForecast().VehicleType();
          if (
            translationSource.VehicleType[vehicleType] &&
            translationSource.VehicleType[vehicleType][translationKey]
          ) {
            translation =
              translationSource.VehicleType[vehicleType][translationKey];
          }
        }
      }
    }

    if (!translation) {
      if (translationSource[translationKey]) {
        translation = translationSource[translationKey];
      }
    }

    return translation;
  }
}

class ViewModel {
  constructor(model: Model) {
    this.model = model;
    (ko as any).observableArray.fn["insertAt"] = function (index, value) {
      this.valueWillMutate();
      this.splice(index, 0, value);
      this.valueHasMutated();
      return this;
    };

    (ko as any).observableArray.fn["removeAt"] = function (index) {
      this.valueWillMutate();
      this.splice(index, 1);
      this.valueHasMutated();
      return this;
    };

    this.selectedLine.subscribe((value) => {
      this.directions([]);
      this.stopPoints([]);

      if (typeof value !== "undefined") {
        if (this.useDirection()) {
          this.getDirections();
        } else {
          this.getStopPoints();
        }
      }
    });

    this.selectedDirection.subscribe((value) => {
      if (typeof value !== "undefined") {
        this.getStopPoints();
      }
    });
  }

  start() {
    this.getConfigOptions();
  }

  size = ko.observable(SizeMode.Normal);
  totalTicks: number = 0;
  frameStateMap = new Map<FrameSourceType, ViewModelFrameState>();
  elapsedSeconds = 0;
  TIMEOUT_INTERVAL_SECONDS = 15;

  selectedRow: Row = null;
  selectedMapRoute: MapRoute = null;
  systemDateTimeOffset = 0;
  mqListMedium = window.matchMedia("(min-width: 768px)");
  mqListLarge = window.matchMedia("(min-width: 992px)");

  getNow(this: ViewModel): Date {
    return new Date(Date.now() + this.systemDateTimeOffset);
  }

  model: Model;
  showVehicleLocations = false;
  showAllStopsCancelledText = ko.observable(false);
  searchMap: L.Map = null;
  searchMapStopAreaMarkers = [];
  resultMap: L.Map = null;
  currentPosMarker: L.Marker = null;
  vehicleMarker: VehicleLocationMarker = null;

  searchParameters: ko.Observable<SearchParameters> = ko.observable(null);

  isLoadingResultList = ko.observable(false);

  year = new Date().getFullYear();
  calls = ko.observableArray<Row>();
  header = ko.pureComputed(() => {
    let result = "";
    if (this.searchParameters() && this.searchParameters().hasData()) {
      result = this.resultStopArea() ? this.resultStopArea().text() : "";
    } else {
      result = this.textPleaseDefineYourSearch();
    }
    return result;
  });
  messages = ko.observableArray<Message>();

  messagesWithoutAffected = ko.pureComputed(() => {
    if (this.useNewTable()) {
      const TotalIndexes = this.calls().reduce(
        (total, x) => total + x.Calls().length,
        0
      );
      if (TotalIndexes == 1) {
        return this.messages().filter(
          (x) => (x.isInfo() && x.AffectedCalls().length == 0)
        );
      }

      return this.messages().filter(
        (x) => x.AffectedCalls().length === TotalIndexes || (x.isInfo() && x.AffectedCalls().length == 0)
      );
    } else {
      return this.messages();
    }
  });

  resultStopArea = ko.observable<StopArea>(null);
  resultMapStopAreaMarker = ko.observable<L.Marker>(null);

  // Select by route, direction and stop point.
  lines = ko.observableArray<Line>();
  selectedLine = ko.observable<Line>();
  directions = ko.observableArray<Direction>();
  selectedDirection = ko.observable<Direction>();
  stopPoints = ko.observableArray<StopArea>([]);
  selectedStopPoint = ko.observable<StopArea>();
  useDirection = ko.observable(false);
  useNewTable = ko.observable(false);
  displayRowMapExpanded = ko.observable(false);
  hideRouteStopSelection = ko.observable(false);
  isCallListFocused = ko.observable(false);
  //stopNameFocus = () => $('#StopName').val('');

  top_pushpin = ko.observable("");
  logo = ko.observable("");
  logo_top = ko.observable("");
  hrefCustomer = ko.observable("");
  iconWheelchair = ko.observable("");
  iconRealtime = ko.observable("");
  iconHash = ko.observable("#");

  ariaHasSuggestionPopup = ko.observable(false);

  tableDetailsShowNext = ko.observable(true);
  tableDetailsShowAfter = ko.observable(true);
  tableDetailsShowMap = ko.observable(true);
  tableDetailsShowOccupancyLegend = ko.observable(true);

  textRowLabelHeader = ko.pureComputed(() => {
    switch (this.model.config.rowLabel) {
      case "Line":
        return this.textLine();
      case "Journey":
        return this.textJourney();
    }
    return "";
  });

  clearStopName() {
    $("#StopName").val("");
  }

  textAllStopsCancelledString = ko.observable("");
  textSelectYourStop = ko.observable("");
  textDoYouKnowStop = ko.observable("");
  textUseGPS = ko.observable("");
  textStopNameOrNumber = ko.observable("");
  textChooseLineStop = ko.observable("");
  textChooseLineDirectionStop = ko.observable("");
  textGetChooseHeader = ko.pureComputed(() => {
    if (this.useDirection()) return this.textChooseLineDirectionStop();
    return this.textChooseLineStop();
  });
  textDirection = ko.observable("");
  textDestination = ko.observable("");
  textOccupancy = ko.observable("");
  textLine = ko.observable("");
  textJourney = ko.observable("");
  textStop = ko.observable("");
  textSelectLine = ko.observable("");
  textSelectDirection = ko.observable("");
  textLoadingDirection = ko.observable("");
  textSelectStop = ko.observable("");
  textLoadingStop = ko.observable("");
  textNext = ko.observable("");
  textStopAreaNumber = ko.observable("");
  textNextDepartureIn = ko.observable("");
  textOr = ko.observable("");
  textAfter = ko.observable("");
  textMin = ko.observable("");
  textViewLiveMap = ko.observable("");
  textCopyright = ko.observable("");
  htmlPoweredBy = ko.observable("");
  textNoDataFound = ko.observable("");
  textLiveBusTimes = ko.observable("");
  textCancelled = ko.observable("");
  textNoFollowingDeparture = ko.observable("");
  textCa = ko.observable("");
  textNow = ko.observable("");
  textFailedGpsLocation = ko.observable("");
  textAriaLabelGps = ko.observable("");
  textAriaLabelSearch = ko.observable("");
  textAriaOr = ko.observable("");
  textPleaseDefineYourSearch = ko.observable("");

  vehicleLocationMarkers: VehicleLocationMarker[] = [];
  secToLocationUpdate = -1;

  findKoCall(rowId): Row {
    var length = this.calls().length;
    for (var i = 0; i < length; i++) {
      if (this.calls()[i].Id === rowId) {
        return this.calls()[i];
      }
    }
    return null;
  }

  purgeCalls() {
    for (var i = this.calls().length - 1; 0 <= i; i--) {
      let call = this.calls()[i];
      if (!call.IsDirty()) {
        (this.calls as any).removeAt(i);
      }
      call.IsDirty(false);
    }
  }

  getDirections() {
    $("#SelectedStopPoint").unwait();
    $("#SelectedDirection").wait();
    $("#SelectedDirection").attr("aria-busy", "true");
    $("#SelectedDirection")
      .find("option:first-child")
      .text(this.textLoadingDirection());

    let data = { lineId: this.selectedLine().id() };
    $.ajax({
      type: "GET",
      contentType: "application/json; charset=utf-8",
      url: this.model.config.api.baseUri + "api/GetDirections",
      data: data,
      dataType: "json",
      success: (response) => {
        let directions = response.map(function (api) {
          let dir = new Direction();
          dir.update(api);
          return dir;
        });
        this.directions(directions);
        $("#SelectedDirection").unwait();
        $("#SelectedDirection")
          .find("option:first-child")
          .text(this.textSelectDirection());
        $("#SelectedDirection").attr("aria-busy", "false");
      },
      error: (response) => {
        console.error("Failed: GetDirections");
        $("#SelectedDirection").unwait();
        $("#SelectedDirection")
          .find("option:first-child")
          .text(this.textSelectDirection());
        $("#SelectedDirection").attr("aria-busy", "false");
      },
    });
  }

  getStopPoints() {
    this.stopPoints([]);

    $("#SelectedStopPoint").wait();
    $("#SelectedStopPoint").attr("aria-busy", "true");
    $("#SelectedStopPoint")
      .find("option:first-child")
      .text(this.textLoadingStop());

    let data = {
      lineId: this.selectedLine().id(),
      directionId: this.useDirection() ? this.selectedDirection().id() : null,
    };

    $.ajax({
      type: "POST",
      contentType: "application/json; charset=utf-8",
      url: this.model.config.api.baseUri + "api/GetStopAreas",
      data: JSON.stringify(data),
      dataType: "json",
      success: (response) => {
        let areas = response.map(function (api) {
          let area = new StopArea();
          area.update(api);
          return area;
        });
        this.stopPoints(areas);
        $("#SelectedStopPoint").unwait();
        $("#SelectedStopPoint")
          .find("option:first-child")
          .text(this.textSelectStop());
        $("#SelectedStopPoint").attr("aria-busy", "false");
      },
      error: (response) => {
        console.error("Failed: GetStopAreas");
        $("#SelectedStopPoint").unwait();
        $("#SelectedStopPoint")
          .find("option:first-child")
          .text(this.textSelectStop());
        $("#SelectedStopPoint").attr("aria-busy", "false");
      },
    });
  }

  //
  // self.selectedStopPoint.subscribe(function (value) {
  //     if (typeof value !== 'undefined'); else;
  // });

  selectRow = (call: Row, evt: any): boolean => {
    this.selectedRow = call;
    if (this.resultMap) {
      L.DomUtil.remove(this.resultMap.getContainer());
      this.resultMap.remove();
      this.resultMap = undefined;
    }

    this.searchParameters().callLineDest("");
    const length = this.calls().length;
    for (var i = 0; i < length; i++) {
      var c = this.calls()[i];
      if (c.Id !== call.Id) {
        c.IsExpanded(false);
      } else {
        if (this.useNewTable()) {
          this.toggleCollapse(call, i);
        }

        if (c.IsExpanded()) {
          if (this.displayRowMapExpanded()) {
            this.toggleShowOnMap(call, i);
            this.searchParameters().callLineDest(
              call.Line() + "-" + call.Dest()
            );
          }
        }
      }
    }
    this.searchParameters().updateUri();
    this.callresize();
    return true;
  };

  toggleCollapse(row: Row, index) {
    this.closeAllToggles();
    // Optionally toggle the aria-expanded attribute for accessibility
    row.IsExpanded(!row.IsExpanded());

    // Toggle display of the content
    const collapseContent = document.querySelector(
      "#collapse-" + index
    ) as HTMLElement;
    if (row.IsExpanded()) {
      collapseContent.style.display = "block";
    } else {
      collapseContent.style.display = "none";
    }
  }

  closeAllToggles() {
    const allAccordions = document.getElementsByClassName(
      "accordion-content"
    ) as HTMLCollectionOf<HTMLButtonElement>;

    for (let i = 0; i < allAccordions.length; i++) {
      const collapseContent = document.querySelector(
        "#collapse-" + i
      ) as HTMLElement;
      collapseContent.style.display = "none";

      const button = allAccordions[i] as HTMLButtonElement;
      button.ariaExpanded = "false";
    }
  }

  toggleShowOnMap(row: Row, index: number) {
    const isShown: boolean = !!this.resultMap;

    if (isShown) {
      this.hideRowMap(row, index);
    } else {
      this.showRowMap(row, index);
    }
  }

  hideRowMap(row: Row, index: number) {
    this.removeJourneyVehicleMarker();
    if (this.resultMap) {
      L.DomUtil.remove(this.resultMap.getContainer());
      this.resultMap.remove();
      this.resultMap = undefined;
    }
  }

  showRowMap(row: Row, index: number) {
    this.removeJourneyVehicleMarker();

    if (this.selectedMapRoute !== null) {
      if (this.resultMap) {
        this.selectedMapRoute.removeFrom(this.resultMap);
      }
      this.selectedMapRoute = null;
    }

    let areaMarker = this.resultMapStopAreaMarker();
    if (areaMarker) {
      if (this.resultMap) {
        this.resultMap.removeLayer(areaMarker);
      }
      this.resultMapStopAreaMarker(null);
    }

    let data = { routeId: row.RouteId() };
    $.ajax({
      type: "GET",
      contentType: "application/json; charset=utf-8",
      url: this.model.config.api.baseUri + "api/GetMapRoute",
      data: data,
      dataType: "json",
      success: (response) => {
        // const rgb = response.lineAppearance.background;
        const rgb = "#FF0000";
        const latlngs = new Array(),
          polylineOptions = { color: rgb };

        for (var i in response.locations) {
          latlngs.push([response.locations[i].lat, response.locations[i].lon]);
        }

        if (this.resultMap) {
          L.DomUtil.remove(this.resultMap.getContainer());
          this.resultMap.remove();
          this.resultMap = undefined;
        }
        if (this.useNewTable()) {
          L.DomUtil.create("div", "", L.DomUtil.get("map-" + index)).id =
            "resultmap";
        } else {
          L.DomUtil.create("div", "", L.DomUtil.get("mapold-" + index)).id =
            "resultmap";
        }
        this.resultMap = L.map("resultmap", {
          center: [
            this.model.config.map.centerLat,
            this.model.config.map.centerLon,
          ],
          zoom: this.model.config.map.zoom,
          zoomAnimation: false,
          fadeAnimation: false,
          markerZoomAnimation: false,
        });

        this.selectedMapRoute = new MapRoute();

        this.selectedMapRoute.polyline = L.polyline(latlngs, polylineOptions);
        this.selectedMapRoute.polylineDirection = L.polylineDecorator(
          this.selectedMapRoute.polyline,
          {
            patterns: [
              {
                offset: 50,
                repeat: 50,
                symbol: L.Symbol.arrowHead({
                  pixelSize: 10,
                  pathOptions: { fillOpacity: 1, weight: 0, color: rgb },
                }),
              },
            ],
          }
        );

        this.selectedMapRoute.addTo(this.resultMap);

        let tileLayer = this.createTileLayer();
        tileLayer.addTo(this.resultMap);

        this.resultMap.fitBounds(this.selectedMapRoute.getBounds(), {});

        let area = this.resultStopArea();
        if (area) {
          let latLng = area.location().toLeaflet();
          let marker = L.marker(latLng, {
            title: area.text(),
            icon: L.icon({
              iconUrl: "assets/img/stop-icon.svg",
              iconSize: [25, 41],
              iconAnchor: [13, 42],
              className: "marker-stop",
            }),
          }).addTo(this.resultMap);
          this.resultMapStopAreaMarker(marker);
        }

        if (row.NextRealtime() && this.selectedRow != null) {
          this.getVehicleLocation(this.selectedRow.CallId(), true);
        }

        this.callresize();
      },
      error: (response) => {
        console.error("Failed: GetMapRoute");
        this.callresize();
      },
    });
  }

  getVehicleLocation(callId: string, isNew: boolean): void {
    $.ajax({
      type: "GET",
      contentType: "application/json; charset=utf-8",
      url: this.model.config.api.baseUri + "api/GetVehiclePosition",
      data: { callId: callId },
      dataType: "json",
      success: (response) => {
        if (response) {
          const vehiclePosition = new VehiclePosition();
          vehiclePosition.update(response);
          if (isNew) {
            this.addJourneyVehicleMarker(vehiclePosition);
          } else {
            this.vehicleMarker
              ? this.updateJourneyVehicleMarker(
                  this.vehicleMarker,
                  vehiclePosition
                )
              : this.addJourneyVehicleMarker(vehiclePosition);
          }
        } else {
          this.removeJourneyVehicleMarker();
        }
      },
      error: function (response) {
        console.error("Failed: GetVehiclePosition");
      },
    });
  }

  getVehicleLocations() {
    $.ajax({
      type: "GET",
      contentType: "application/json; charset=utf-8",
      url: this.model.config.api.baseUri + "api/GetVehiclePositions",
      data: {},
      dataType: "json",
      success: (response) => {
        if (response) {
          this.searchMap
            .getPane("markerPane")
            .setAttribute("animation", "true");
          const vehiclePositions = _.map(response, (api) => {
            const vehiclePosition = new VehiclePosition();
            vehiclePosition.update(api);
            return vehiclePosition;
          });
          this.updateVehicleLocations(vehiclePositions);
          this.secToLocationUpdate = 3;
        }
      },
      error: function (response) {
        console.error("Failed: GetVehiclePositions");
      },
    });
  }

  updateVehicleLocations(locations: VehiclePosition[]): void {
    const locMap = _.keyBy(locations, (o) => {
      return o.id();
    });
    const updatedLocations = [];
    const markerMap = _.keyBy(this.vehicleLocationMarkers, (o) => {
      return o.id;
    });
    for (let i = 0; i < this.vehicleLocationMarkers.length; i++) {
      if (!_.has(locMap, this.vehicleLocationMarkers[i].id)) {
        this.vehicleLocationMarkers[i].marker.remove(); // Remove marker
      }
    }
    for (let i = 0; i < locations.length; i++) {
      let m: VehicleLocationMarker = null;
      const loc = locations[i];
      if (!_.has(markerMap, loc.id())) {
        m = this.createVehicleMarker(loc);
        m.marker.addTo(this.searchMap);
      } else {
        m = markerMap[loc.id()];
        m.updatePosition(loc);
        m.updateHeading(loc);
      }
      updatedLocations.push(m);
    }
    this.vehicleLocationMarkers = updatedLocations;
    this.updateVehicleMarkerIcons();
  }

  updateVehicleMarkerIcons(): void {
    let zoomLevel = this.searchMap.getZoom();
    let mapBounds = this.searchMap.getBounds();
    for (let i = 0; i < this.vehicleLocationMarkers.length; i++) {
      let m = this.vehicleLocationMarkers[i];
      if (zoomLevel >= 12 && mapBounds.contains(m.latlng)) {
        if (m.iconType !== VehicleMarkerIconType.Detailed) {
          m.marker.setIcon(m.iconDetailed);
          m.iconType = VehicleMarkerIconType.Detailed;
        }
      } else {
        if (m.iconType !== VehicleMarkerIconType.Normal) {
          m.marker.setIcon(m.iconNormal);
          m.iconType = VehicleMarkerIconType.Normal;
        }
      }
    }
  }

  compareRows = (r1: Row, r2: Row) => {
    let result = 0;
    for (let index = 0; index < this.model.config.rowOrder.length; index++) {
      if (result !== 0) break;

      let rowOrder = this.model.config.rowOrder[index];
      switch (rowOrder) {
        case "Line":
          result = AlphanumComparer(r1.Line(), r2.Line());
          break;
        case "Journey":
          result = AlphanumComparer(r1.Journey(), r2.Journey());
          break;
        case "Destination":
          result = AlphanumComparer(r1.Dest(), r2.Dest());
          break;
        case "Time":
          result = Compare(r1.NextDeparture(), r2.NextDeparture());
          break;
      }
    }

    return result;
  };

  compareLines(l1: Line, l2: Line) {
    return AlphanumComparer(l1.text(), l2.text());
  }

  compareStopAreas(a1: StopArea, a2: StopArea) {
    return AlphanumComparer(a1.text(), a2.text());
  }

  compareText(d1: Direction, d2: Direction) {
    return AlphanumComparer(d1.text(), d2.text());
  }

  clickUpdateVehicles() {
    this.getVehicleLocations();
  }

  clearAllSearchMapStopAreaMarkers() {
    if (!this.searchMap) {
      return;
    }

    this.searchMapStopAreaMarkers.map((marker) => {
      this.searchMap.removeLayer(marker);
    });

    this.searchMapStopAreaMarkers = [];
  }

  onStopAreaMarkerClick(e) {
    // Get the name of the stop and remove the first word (distance).
    var words = e.target.options.title.split(" ");
    words.shift();

    $("#StopName").val(words.join(" "));
    let searchParameters = new SearchParameters();
    searchParameters.fromStopAreaQuery($("#StopName").val().toString());
    this.search(searchParameters);
    if (this.searchMap) {
      this.toggleSearchMap();
    }
  }

  createTileLayer(): L.TileLayer {
    const options = {};
    _.assign(options, this.model.config.map.tilesOptions);
    options["minZoom"] = this.model.config.map.minZoom;
    const tileLayer = L.tileLayer(this.model.config.map.tilesUrl, options);
    return tileLayer;
  }

  createVehicleMarker(position: VehiclePosition): VehicleLocationMarker {
    const marker = new VehicleLocationMarker();
    marker.id = position.id();

    marker.vehicleDiv = document.createElement("div");
    marker.vehicleImg = document.createElement("img");
    marker.vehicleImg.classList.add("icon-vehicle");
    marker.vehicleImg.style.position = "absolute";
    marker.vehicleImg.style.zIndex = "1";
    marker.vehicleImg.src = "assets/img/vehicleIcon68.png";

    marker.vehicleArrowImg = document.createElement("img");
    marker.vehicleArrowImg.classList.add("heading");
    marker.vehicleArrowImg.style.position = "absolute";
    marker.vehicleArrowImg.style.zIndex = "2";
    marker.vehicleArrowImg.src = "assets/img/vehicleArrowIcon68.png";

    marker.vehicleArrowImg.style.transition = "all 1s ease-in";
    marker.vehicleDiv.style.transition = "all 1s ease-in";

    marker.vehicleDiv.appendChild(marker.vehicleImg);
    marker.vehicleDiv.appendChild(marker.vehicleArrowImg);

    let classesDetailed = "marker-vehicle detailed";
    let classesNormal = "marker-vehicle normal";
    if (position.classes()) {
      classesDetailed = `${classesDetailed} ${position.classes().join(" ")}`;
      classesNormal = `${classesNormal} ${position.classes().join(" ")}`;
    }
    marker.iconDetailed = L.divIcon({
      className: classesDetailed,
      html: marker.vehicleDiv as any,
      iconSize: [69, 69],
    });
    marker.iconNormal = L.icon({
      iconUrl: "assets/img/vehicleArrow.svg",
      iconSize: [40, 40],
      iconAnchor: [20, 20],
      popupAnchor: [-3, -76],
      className: classesNormal,
      //,shadowUrl: 'my-icon-shadow.png',
      //shadowSize: [68, 95],
      //shadowAnchor: [22, 94]
    });

    marker.marker = L.marker(marker.latlng, {
      title: marker.title,
      icon: marker.iconNormal,
    });
    marker.iconType = VehicleMarkerIconType.Normal;

    marker.title = position.displayName();
    marker.updatePosition(position);
    marker.updateHeading(position);
    return marker;
  }

  addVehicleMarker(
    target: L.Map | L.LayerGroup<any>,
    marker: VehicleLocationMarker
  ) {
    marker.marker.addTo(target);
    marker.marker.getElement().style.transition = "all 1s ease-in";
  }

  addJourneyVehicleMarker(vehiclePosition: VehiclePosition): void {
    this.removeJourneyVehicleMarker();

    if (this.resultMap) {
      this.vehicleMarker = this.createVehicleMarker(vehiclePosition);
      this.vehicleMarker.iconType = VehicleMarkerIconType.Detailed;
      this.vehicleMarker.marker.setIcon(this.vehicleMarker.iconDetailed);
      this.vehicleMarker.marker.addTo(this.resultMap);
    }
  }

  updateJourneyVehicleMarker(
    marker: VehicleLocationMarker,
    vehiclePosition: VehiclePosition
  ): void {
    if (!marker) return;

    marker.updatePosition(vehiclePosition);
    marker.updateHeading(vehiclePosition);
  }

  removeJourneyVehicleMarker(): void {
    if (this.vehicleMarker !== null) {
      if (this.resultMap) {
        this.resultMap.removeLayer(this.vehicleMarker.marker);
      }
      this.vehicleMarker = null;
    }
  }

  addAllSearchMapStopAreaMarkers(stopAreas) {
    if (!this.searchMap) {
      return;
    }

    stopAreas.map((stopArea) => {
      let latLng = stopArea.location().toLeaflet();
      var marker = L.marker(latLng, {
        title: stopArea.text(),
        zIndexOffset: -1000,
        icon: L.icon({
          iconUrl: "assets/img/stopPoint50.png",
          iconSize: [33, 50],
          iconAnchor: [16, 50],
        }),
      }).addTo(this.searchMap);

      marker.on("click", this.onStopAreaMarkerClick.bind(this));

      this.searchMapStopAreaMarkers.push(marker);
    });
  }

  gpsLocation = (position: GeolocationPosition) => {
    $("#GpsButton").wait();

    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;

    this.clearAllSearchMapStopAreaMarkers();

    if (this.currentPosMarker === null) {
      this.currentPosMarker = L.marker(L.latLng(latitude, longitude), {
        title: "You are here",
        icon: L.icon({
          iconUrl: "assets/img/youAreHereIcon136.png",
          iconSize: [64, 64],
          iconAnchor: [32, 64],
        }),
      }).addTo(this.searchMap);
    } else {
      this.currentPosMarker.setLatLng(L.latLng(latitude, longitude));
    }

    this.searchMap.setView([latitude, longitude], 16);
    this.findStopsNearLocation();
  };

  findStopsNearLocation() {
    let center = this.searchMap.getCenter();
    let data = {
      coordinate: {
        lat: center.lat,
        lon: center.lng,
      },
      maxDistance: this.model.config.map.maxCount,
      maxCount: this.model.config.map.maxDistance,
    };
    $.ajax({
      type: "POST",
      contentType: "application/json; charset=utf-8",
      url: this.model.config.api.baseUri + "api/FindStopsNearLocation",
      data: JSON.stringify(data),
      dataType: "json",
      success: (response) => {
        let areas = response.map((api) => {
          let area = new StopArea();
          area.update(api);
          let latLng = area.location().toLeaflet();
          let distance = (
            Math.round(
              this.currentPosMarker.getLatLng().distanceTo(latLng) / 10
            ) * 10
          ).toFixed(0);
          area.text(distance.toString() + "m " + area.text());
          return area;
        });

        this.clearAllSearchMapStopAreaMarkers();
        this.addAllSearchMapStopAreaMarkers(areas);
        $("#GpsButton").unwait();
      },
      error: function (response) {
        console.error("Failed: FindStopsNearLocation");
        $("#GpsButton").unwait();
      },
    });
  }

  gpsLocationError = (positionerror) => {
    console.log(
      "failed gpsLocation(" + positionerror.code + "): " + positionerror.message
    );
    alert(this.textFailedGpsLocation());
    this.gpsLocation({
      coords: {
        latitude: this.model.config.map.centerLat,
        longitude: this.model.config.map.centerLon,
        accuracy: null,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        speed: null,
      },
      timestamp: null,
    });
  };

  populateRoutesDDL() {
    $("#SelectedLine").wait();
    $("#SelectedLine").find("option:first-child").text("Loading");
    $.ajax({
      type: "GET",
      contentType: "application/json; charset=utf-8",
      url: this.model.config.api.baseUri + "api/GetLines",
      dataType: "json",
      success: (response) => {
        $("#SelectedLine").unwait();
        $("#SelectedLine")
          .find("option:first-child")
          .text(this.textSelectLine());
        let guiLines = response.map(function (l) {
          var line = new Line();
          line.update(l);
          return line;
        });
        this.lines(guiLines);
      },
      error: function (response) {
        console.error("Failed: GetLines");
      },
    });
  }

  getCalls(
    params: SearchParameters,
    fn: (search: SearchParameters, data: any) => void
  ) {
    if (!params) {
      return;
    }

    let request = {
      query: {
        fromStopAreaQuery: params.fromStopAreaQuery(),
        toStopAreaName: params.toStopAreaQuery(),
        lineId: params.lineId(),
        directionId: params.directionId(),
      },
      configuration: {
        grouping: this.model.config.rowGrouping,
        //,order: model.config.rowOrder
        //,data: model.config.rowData
      },
    };

    showSpinner();
    this.isLoadingResultList(true);
    $.ajax({
      type: "POST",
      contentType: "application/json; charset=utf-8",
      url: this.model.config.api.baseUri + "api/GetCalls",
      data: JSON.stringify(request),
      dataType: "json",
      success: (response) => {
        fn(params, response);
        this.callresize();
      },
      error: (response) => {
        console.error("Failed: GetCalls");
      },
      complete: () => {
        hideSpinner();
        this.isLoadingResultList(false);
      },
    });
  }

  handleCalls = (params: SearchParameters, info: any) => {
    this.calls([]);

    this.updateCalls(params, info);

    this.isCallListFocused(true);

    if (this.mqListMedium.matches) {
      $("#resulty").scrollintoview({ duration: "normal" });
    }

    if (this.displayRowMapExpanded() && this.calls().length > 0) {
      var call = this.calls()[0];
      var cl = this.searchParameters().callLineDest();
      if (cl.length > 0) {
        var index = _.findIndex(
          this.calls(),
          (c) =>
            cl.startsWith(c.Line()) &&
            cl[c.Line().length] === "-" &&
            cl.endsWith(c.Dest())
        );
        if (index === -1) {
          index = _.findIndex(this.calls(), (c) => cl.endsWith(c.Dest()));
        }
        if (index === -1) {
          index = _.findIndex(
            this.calls(),
            (c) => cl.startsWith(c.Line()) && cl[c.Line().length] === "-"
          );
        }
        if (index >= 0) {
          call = this.calls()[index];
        }
      }

      this.selectRow(call.IsExpanded(true), null);
    }
  };

  updateCalls = (params: SearchParameters, info: any) => {
    if (info && info.calls) {
      const length = info.calls.length;
      for (let i = 0; i < length; i++) {
        var newCall = info.calls[i];
        let koCall = this.findKoCall(newCall.id);

        if (!koCall) {
          koCall = new Row(newCall.id, this.model);
          if (i < this.calls().length) {
            (this as any).calls.insertAt(i, koCall);
          } else {
            this.calls.push(koCall);
          }
        }

        this.updateCall(koCall, newCall);
        this.updateCallFormat(koCall);
        koCall.IsDirty(true);
      }
    }

    this.purgeCalls();

    let messages = [];
    if (info && info.messages) {
      const length = info.messages.length;
      for (let i = 0; i < length; i++) {
        let m = new Message();
        let api = info.messages[i];
        m.update(api);
        messages.push(m);
      }
    }

    if (info && info.isStopCancelled && this.showAllStopsCancelledText()) {
      let m = new Message();
      m.text(this.textAllStopsCancelledString());
      m.isDisturbance(true);
      messages.unshift(m);
    }

    if (this.calls().length === 0 && messages.length === 0) {
      let m = new Message();
      m.text(this.textNoDataFound());
      messages.unshift(m);
    }

    this.messages(messages);
  };

  updateCall(koCall: Row, callRow: any) {
    let call = callRow.calls[0];
    let next = callRow.calls[1];

    while (koCall.Calls().length > callRow.calls.length) {
      koCall.Calls.pop();
    }

    for (let index = 0; index < callRow.calls.length; index++) {
      const apiCall = callRow.calls[index];
      let viewCall = koCall.Calls()[index];
      if (viewCall == null) {
        viewCall = new Call(koCall, this.model);
        koCall.Calls.push(viewCall);
      }
      viewCall.update(apiCall);
    }

    koCall.CallId(call.id);
    koCall.RouteId(call.routeId);
    koCall.JourneyId(call.journeyId);
    koCall.Line(call.line);
    koCall.Journey(call.journey);
    koCall.StopAreaNumber(call?.departure?.designation ?? "");
    koCall.NextStopAreaNumber(next?.departure?.designation ?? "");
    if (call.lineAppearance) {
      koCall.LineAppearance().update(call.lineAppearance);
    } else {
      koCall.LineAppearance().clear();
    }
    koCall.Dest(call.destination);
    koCall.Subdest(call.subdestination);

    // koCall.NextWheelchairAdapted(false); // call.departure.attributes.indexOf('wheelchair') > -1 - configuration = false in previous/overrides realtime
    koCall.NextWheelchairAdapted(
      call.departure.attributes.indexOf("wheelchair") > -1
    );
    koCall.NextRealtime(call.departure.quality === "realtime");
    koCall.NextDeparture(moment(call.departure.forecastTime));
    koCall.IsNextCancelled(
      call.departure.attributes.indexOf("departureCancelled") > -1
    );

    if (!next) {
      koCall.IsAfterRealtime(false);
      koCall.AfterDeparture(null);
      koCall.IsAfterCancelled(false);
    } else {
      koCall.AfterWheelchairAdapted(
        next.departure.attributes.indexOf("wheelchair") > -1
      );
      koCall.IsAfterRealtime(next.departure.quality === "realtime");
      koCall.AfterDeparture(moment(next.departure.forecastTime));
      koCall.IsAfterCancelled(
        next.departure.attributes.indexOf("departureCancelled") > -1
      );
    }

    koCall.CreatedTime(moment(call.createdTime));
  }

  updateCallFormat(koCall: Row) {
    koCall.Next(
      this.formatTime(
        koCall.NextRealtime(),
        koCall.NextDeparture(),
        koCall.CreatedTime(),
        false,
        koCall.IsNextCancelled()
      )
    );
    koCall.NextHhMm(
      this.formatTime(
        koCall.NextRealtime(),
        koCall.NextDeparture(),
        koCall.CreatedTime(),
        true,
        koCall.IsNextCancelled()
      )
    );

    koCall.After(
      this.formatTime(
        koCall.IsAfterRealtime(),
        koCall.AfterDeparture(),
        koCall.CreatedTime(),
        false,
        koCall.IsAfterCancelled()
      )
    );
    koCall.AfterHhMm(
      this.formatTime(
        koCall.IsAfterRealtime(),
        koCall.AfterDeparture(),
        koCall.CreatedTime(),
        true,
        koCall.IsAfterCancelled()
      )
    );
  }

  tooltip(element: Element): string | Element {
    const tooltipRef = $(element).data("tooltip");
    const tooltipHtml = $("#" + tooltipRef)
      .first()
      .clone()[0];
    return tooltipHtml;
  }

  formatTime(
    isRealtime: boolean,
    departureTime,
    createdTime,
    hhmm,
    isCancelled: boolean
  ) {
    if (!departureTime) {
      return this.textNoFollowingDeparture();
    }

    let departure = moment(departureTime);
    let created = moment(createdTime);
    let duration = moment.duration(departure.diff(created));
    if (duration.asSeconds() < 0) {
      duration = moment.duration(0, "seconds");
    }
    let formattedTime = this.textNoFollowingDeparture();
    if (departure && departure.year() < 9999) {
      if (hhmm) {
        formattedTime = this.model.timeFormatter.absoluteTime(departure);
      } else {
        formattedTime = this.model.timeFormatter.formatTime({
          datetime: departure,
          duration,
          isRealtime,
          isCancelled,
          isCongestion: false,
        });
      }
    }
    return formattedTime;
  }

  respondToUrl() {
    let searchParameters = new SearchParameters();
    searchParameters.setFromUri();
    this.search(searchParameters);
  }

  getSimpleStopArea(stopAreaName) {
    $.ajax({
      type: "POST",
      contentType: "application/json; charset=utf-8",
      url: this.model.config.api.baseUri + "api/FindStopArea",
      data: JSON.stringify({ StopAreaQuery: stopAreaName }),
      dataType: "json",
      success: (response) => {
        let area = null;
        if (response) {
          area = new StopArea();
          area.update(response);
        }

        this.resultStopArea(area);
      },
      error: function (response) {
        console.error("Failed: FindStopArea");
      },
    });
  }

  search(searchParameters) {
    this.clearResult();

    if (!searchParameters.fromStopAreaQuery()) {
      this.searchParameters(null);
      return;
    }

    this.searchParameters(searchParameters);
    this.searchParameters().updateUri();
    this.getCalls(this.searchParameters(), this.handleCalls);
    this.getSimpleStopArea(this.searchParameters().fromStopAreaQuery());
  }

  clearResult() {
    this.elapsedSeconds = 0;
    this.resultStopArea(null);
    if (this.selectedMapRoute !== null) {
      if (this.resultMap) {
        this.selectedMapRoute.removeFrom(this.searchMap);
      }
      this.selectedMapRoute = null;
    }

    if (this.resultMap) {
      L.DomUtil.remove(this.resultMap.getContainer());
      this.resultMap.remove();
      this.resultMap = undefined;
    }

    this.messages([]);
  }

  resizeTables() {
    var h1 = $("#resulty").outerHeight();
    var h2 = $("#searchy").outerHeight();
    var footer = $(".content-footer").outerHeight();
    var largestTable = Math.max(h1, h2);
    if (largestTable < window.innerHeight - footer) {
      $(".content-area").height(window.innerHeight - footer);
      $(".content-area-header").height(window.innerHeight - footer);
      $("#header").height(window.innerHeight - footer);
      $("#headerimage").height(window.innerHeight - footer);
    } else {
      $(".content-area").height(largestTable);
      $(".content-area-header").height(largestTable);
      $("#header").height(window.innerHeight - footer);
      $("#headerimage").height(largestTable);
    }
  }

  resizeTablesSm() {
    $("#header").height(100);
    $(".content-area-header").height(100);
    var h1 = $("#resulty").outerHeight();
    var h2 = $("#searchy").outerHeight();
    var footer = $(".content-footer").outerHeight();
    var header = $(".content-area-header").outerHeight();
    var largestTable = Math.max(h1, h2);
    if (largestTable < window.innerHeight - footer - header) {
      $(".content-area").height(window.innerHeight - footer - header);
    } else {
      $(".content-area").height(largestTable);
    }
    $("#headerimage").css("height", "100%");
  }

  resizeTablesXs() {
    $("#header").height(100);
    $(".content-area-header").height(100);
    $(".content-area").css("height", "auto");
    $("#header").css("height", "100%");
  }

  resizeCallTable() {
    const columnLineElements = $(".line, .lineHeader");
    columnLineElements.css({ width: "", "min-width": "", "max-width": "" });

    const columnDestinationElements = $(".destination, .destinationHeader");
    columnDestinationElements.css({
      width: "",
      "min-width": "",
      "max-width": "",
    });

    const columnNextElements = $(".next, .nextHeader");
    columnNextElements.css({ width: "", "min-width": "", "max-width": "" });

    const columnStopAreaNumberElements = $(
      ".stopAreaNumber, .stopAreaNumberHeader"
    );
    columnStopAreaNumberElements.css({
      width: "",
      "min-width": "",
      "max-width": "",
    });

    const columnOccupancyElements = $(".occupancy, .occupancyHeader");
    columnOccupancyElements.css({
      width: "",
      "min-width": "",
      "max-width": "",
    });

    const columnExpanderElements = $(".expander, .expanderHeader");
    columnExpanderElements.css({ width: "", "min-width": "", "max-width": "" });

    const columnLineWidth = this.maxOuterWidth(columnLineElements);
    if (!isNaN(columnLineWidth)) {
      columnLineElements.css({
        "min-width": `${columnLineWidth}px`,
        "max-width": `${columnLineWidth}px`,
      });
    }

    const columnNextWidth = this.maxOuterWidth(columnNextElements);
    if (!isNaN(columnNextWidth)) {
      columnNextElements.css({
        "min-width": `${columnNextWidth}px`,
        "max-width": `${columnNextWidth}px`,
      });
    }

    const columnStopAreaNumberWidth = this.maxOuterWidth(
      columnStopAreaNumberElements
    );
    if (!isNaN(columnStopAreaNumberWidth)) {
      columnStopAreaNumberElements.css({
        "min-width": `${columnStopAreaNumberWidth}px`,
        "max-width": `${columnStopAreaNumberWidth}px`,
      });
    }

    const columnOccupancyWidth = this.maxOuterWidth($(".occupancyHeader"));
    if (!isNaN(columnOccupancyWidth)) {
      columnOccupancyElements.css({
        "min-width": `${columnOccupancyWidth}px`,
        "max-width": `${columnOccupancyWidth}px`,
      });
    }

    const columnExpanderWidth = this.maxOuterWidth($(".expanderHeader"));
    if (!isNaN(columnExpanderWidth)) {
      columnExpanderElements.css({
        "min-width": `${columnExpanderWidth}px`,
        "max-width": `${columnExpanderWidth}px`,
      });
    }
    const tableWidth = $(".tc2").innerWidth();

    const columnDestinationWidth =
      tableWidth -
      (columnExpanderWidth || 0) -
      (columnOccupancyWidth || 0) -
      (columnNextWidth || 0) -
      (columnStopAreaNumberWidth || 0) -
      (columnLineWidth || 0);
    if (!isNaN(columnDestinationWidth)) {
      columnDestinationElements.css({
        "min-width": `${columnDestinationWidth}px`,
        "max-width": `${columnDestinationWidth}px`,
      });
    }
  }

  maxOuterWidthElement(query: globalThis.JQuery<HTMLElement>): HTMLElement {
    let element: HTMLElement = null;
    let maxWidth: number = NaN;
    query.each(function () {
      let itemWidth: number = NaN;
      const selector = $(this);
      if (selector.is(":visible")) {
        itemWidth = $(this).outerWidth(true);
      }
      if (isNaN(itemWidth)) {
        return;
      }
      if (isNaN(maxWidth)) {
        maxWidth = itemWidth;
        element = this;
      } else {
        if (itemWidth > maxWidth) {
          element = this;
        }
      }
    });

    return element;
  }

  maxOuterWidth(
    query: globalThis.JQuery<HTMLElement>,
    def: number = NaN
  ): number {
    let maxWidth: number = NaN;
    query.each(function () {
      let itemWidth: number = NaN;
      const selector = $(this);
      if (selector.is(":visible")) {
        itemWidth = $(this).outerWidth(true);
      }
      if (isNaN(itemWidth)) {
        return;
      }
      if (isNaN(maxWidth)) {
        maxWidth = itemWidth;
      } else {
        maxWidth = Math.max(maxWidth, itemWidth);
      }
    });

    if (isNaN(maxWidth)) {
      maxWidth = def;
    }
    return maxWidth;
  }

  callresize() {
    if (this.mqListLarge.matches) {
      this.model.viewModel.size(SizeMode.Normal);
      this.resizeTables();
    } else if (this.mqListMedium.matches) {
      this.model.viewModel.size(SizeMode.Small);
      this.resizeTablesSm();
    } else {
      this.model.viewModel.size(SizeMode.ExtraSmall);
      this.resizeTablesXs();
    }
    this.resizeCallTable();
  }

  clock(date: Date) {
    let time = moment(date);
    if (!this.model.config.am_pm) {
      $("#clockcontent").html(time.format(this.model.config.formatClock));
    } else {
      let suffix = time.format("A");
      $("#clockcontent").html(
        time.format(this.model.config.formatClock) +
          " " +
          '<span class="ampm">' +
          suffix +
          "</span>"
      );
    }
  }

  tick(date: Date) {
    this.totalTicks++;

    this.clock(date);
    this.updateFrameState();

    if (!this.isLoadingResultList()) {
      let searchParams = this.searchParameters();
      if (
        searchParams &&
        ++this.elapsedSeconds === this.TIMEOUT_INTERVAL_SECONDS
      ) {
        this.getCalls(searchParams, this.updateCalls);

        if (this.selectedRow && this.resultMap) {
          this.getVehicleLocation(this.selectedRow.CallId(), false);
        }

        this.elapsedSeconds = 0;
      }
    }

    if (this.showVehicleLocations && this.searchMap) {
      if (this.secToLocationUpdate > 0) {
        this.secToLocationUpdate -= 1;
      } else if (this.secToLocationUpdate === 0) {
        this.secToLocationUpdate = -1;
        this.getVehicleLocations();
      }
    }
  }

  private updateFrameState(): void {
    const currentTicks = this.totalTicks;
    const sources: (keyof typeof FrameSourceType)[] = <
      (keyof typeof FrameSourceType)[]
    >Object.keys(FrameSourceType);
    for (let source of sources) {
      const sourceType: FrameSourceType = FrameSourceType[source];
      let frameState = this.model.viewModel.frameStateMap.get(sourceType);
      let frameConf = this.model.config.frames.frameMap.get(sourceType);
      if (frameConf == null) {
        continue;
      }
      let transition = false;
      let newFrame: Frame = null;
      if (frameState == null) {
        frameState = new ViewModelFrameState();
        this.model.viewModel.frameStateMap.set(sourceType, frameState);
        newFrame = frameConf[0];
        transition = true;
      } else if (
        frameState.frame() &&
        currentTicks - frameState.startTick >= frameState.frame().seconds
      ) {
        const nextFrameIndex =
          (frameState.frame().index + 1) % frameConf.length;
        newFrame = frameConf[nextFrameIndex];
        transition = true;
      }

      if (transition) {
        frameState.startTick = currentTicks;
        frameState.frame(newFrame);
      }
    }
  }

  toggleSearchMap() {
    if (this.showVehicleLocations) {
      if (!this.searchMap) {
        $("#map_overlay_div").show();

        this.searchMap = L.map("map_overlay_div", {
          center: [
            this.model.config.map.centerLat,
            this.model.config.map.centerLon,
          ],
          zoom: this.model.config.map.zoom,
          zoomAnimation: false,
          fadeAnimation: false,
          markerZoomAnimation: false,
        });

        let tileLayer = this.createTileLayer();
        tileLayer.addTo(this.searchMap);

        let closeMeControl = closeMapControl({ options: "topright" });
        closeMeControl.addTo(this.searchMap);
        L.DomEvent.on(closeMeControl.getContainer(), "click", (event) => {
          this.toggleSearchMap();
        });

        this.searchMap.on("moveend", (ev) => {
          this.findStopsNearLocation();
          this.updateVehicleMarkerIcons();
        });
        this.searchMap.on("zoomstart", (ev) => {
          this.searchMap
            .getPane("markerPane")
            .setAttribute("animation", "false");
        });
        this.searchMap.on("zoomend", (ev) => {});

        this.getVehicleLocations();

        this.callresize();
        return true;
      } else {
        this.updateVehicleLocations([]);
        this.searchMap.remove();
        this.currentPosMarker = null;
        this.searchMap = null;
        $("#map_overlay_div").hide();
        return false;
      }
    } else {
      if (!this.searchMap) {
        $('<div id="searchmap" class="map"></div>').prependTo(
          "#search_map_div"
        );

        this.searchMap = L.map("searchmap", {
          center: [
            this.model.config.map.centerLat,
            this.model.config.map.centerLon,
          ],
          zoom: this.model.config.map.zoom,
          zoomAnimation: false,
          fadeAnimation: false,
          markerZoomAnimation: false,
        });

        let tileLayer = this.createTileLayer();
        tileLayer.addTo(this.searchMap);

        this.callresize();
        return true;
      } else {
        $("#searchmap").remove();
        this.currentPosMarker = null;
        this.searchMap = null;
        this.callresize();
        return false;
      }
    }
  }

  getConfigOptions() {
    ko.applyBindings(this);

    $.ajax({
      type: "GET",
      contentType: "application/json; charset=utf-8",
      url:
        this.model.config.api.baseUri +
        "api/GetConfigOptions?profile=" +
        param("profile") +
        "&language=" +
        param("language"),
      dataType: "json",
      success: (response) => {
        if (response) {
          document.title = response.title;
          document.documentElement.lang = response.textLanguage;
          $("link[rel*='icon']").attr("href", response.favicon);
          let profileData = param("profileData");
          if (!profileData) {
            profileData = param("data");
          }
          if (!profileData) {
            profileData = response.profileData;
          }
          let profileDisplay = param("profile");
          if (!profileDisplay) {
            profileDisplay = response.profileDisplay;
          }
          const headers = {};
          if (profileData) {
            headers["anyride-profile-data"] = profileData;
          }
          if (profileDisplay) {
            headers["anyride-profile-display"] = profileDisplay;
          }
          $.ajaxSetup({
            headers: headers,
          });

          if (response.customCss.length > 0) {
            let customCss = $(
              '<link href="' +
                response.customCss +
                '" rel="stylesheet" type="text/css" />'
            );
            let finallyCustomCss = () => {
              this.model.bootstrap.themeLoaded = true;
              this.model.bootstrap.onUpdate();
            };
            customCss.on("load", finallyCustomCss);
            customCss.on("error", finallyCustomCss);
            $("head").append(customCss);
          } else {
            this.model.bootstrap.themeLoaded = true;
            this.model.bootstrap.onUpdate();
          }

          this.model.config.locale = response.locale;
          if (!this.model.config.locale) {
            this.model.config.locale =
              window.navigator["userLanguage"] || window.navigator.language;
          }
          moment.locale(this.model.config.locale);

          this.model.resourceModel.setSource(response);
          this.model.config.map.centerLat = response.centerLat;
          this.model.config.map.centerLon = response.centerLon;
          this.model.config.map.maxCount = response.mapMaxCount;
          this.model.config.map.maxDistance = response.mapMaxDistance;
          this.model.config.map.zoom = response.zoom;
          this.model.config.map.minZoom = response.minZoom;
          let hostname = this.model.config.api.baseUri;
          let index = hostname.indexOf("//");
          if (index >= 0) {
            hostname = hostname.substring(index + 2);
          }
          index = hostname.indexOf("/");
          if (index >= 0) {
            hostname = hostname.substring(0, index);
          }
          this.model.config.map.tilesUrl = response.tiles.urlTemplate.replace(
            "{host}",
            hostname
          );
          _.forEach(response.tiles.options, (value) => {
            _.assign(this.model.config.map.tilesOptions, value);
          });

          this.model.config.frames = ConfigurationFrames.parse(response.frames);
          this.model.config.occupancy = ConfigurationOccupancy.parse(
            response.occupancy
          );

          this.model.config.formatClock = response.formatClock;
          this.model.config.am_pm = response.am_pm;
          this.model.config.formatAbsoluteTime = response.formatAbsoluteTime;
          this.model.config.formatRelativeTime = response.formatRelativeTime;
          this.model.config.formatForecastTime = response.formatForecastTime;
          this.model.config.useNonForcastIndicator =
            response.useNonForcastIndicator;

          this.model.config.rowGrouping = response.rowGrouping;
          this.model.config.rowOrder = response.rowOrder;
          this.model.config.rowData = response.rowData;
          this.model.config.rowLabel = response.rowLabel;

          this.model.config.resultTable.showStopAreaNumber(
            response.showStopAreaNumber
          );
          this.model.config.resultTable.showOccupancy(
            response.tableShowOccupancy
          );
          this.model.config.resultTable.showExpander(
            response.tableShowExpander
          );
          this.useNewTable(response.useNewTable);

          this.tableDetailsShowNext(response.tableDetailsShowNext);
          this.tableDetailsShowAfter(response.tableDetailsShowAfter);
          this.tableDetailsShowMap(response.tableDetailsShowMap);
          this.tableDetailsShowOccupancyLegend(
            response.tableDetailsShowOccupancyLegend
          );

          this.iconWheelchair(response.iconWheelchair);
          this.iconRealtime(response.iconRealtime);

          this.showVehicleLocations = response.showVehicleLocations;
          this.showAllStopsCancelledText(response.showAllStopsCancelledText);
          this.useDirection(response.useDirection);
          this.displayRowMapExpanded(response.displayRowMapExpanded);
          this.hideRouteStopSelection(response.hideRouteStopSelection);
          this.logo(response.logo);
          this.logo_top(response.logo_top);
          this.hrefCustomer(response.hrefCustomer);
          this.top_pushpin(response.top_pushpin);

          this.model.translationModel.setSource(response);
          this.textAllStopsCancelledString(
            response.textAllStopsCancelledString
          );
          this.textSelectYourStop(response.textSelectYourStop);
          this.textDoYouKnowStop(response.textDoYouKnowStop);
          this.textUseGPS(response.textUseGPS);
          this.textStopNameOrNumber(response.textStopNameOrNumber);
          this.textChooseLineStop(response.textChooseLineStop);
          this.textChooseLineDirectionStop(
            response.textChooseLineDirectionStop
          );
          this.textDirection(response.textDirection);
          this.textDestination(response.textDestination);
          this.textOccupancy(response.textOccupancy);
          this.textLine(response.textLine);
          this.textJourney(response.textJourney);
          this.textStop(response.textStop);
          this.textSelectLine(response.textSelectLine);
          this.textSelectDirection(response.textSelectDirection);
          this.textLoadingDirection(response.textLoadingDirection);
          this.textSelectStop(response.textSelectStop);
          this.textLoadingStop(response.textLoadingStop);
          this.textNext(response.textNext);
          this.textStopAreaNumber(response.textStopAreaNumber);
          this.textNextDepartureIn(response.textNextDepartureIn);
          this.textOr(response.textOr);
          this.textAfter(response.textAfter);
          this.textMin(response.textMin);
          this.textViewLiveMap(response.textViewLiveMap);
          this.textCopyright(response.textCopyright);
          this.htmlPoweredBy(response.htmlPoweredBy);
          this.textNoDataFound(response.textNoDataFound);
          this.textLiveBusTimes(response.textLiveBusTimes);
          this.textCancelled(response.textCancelled);
          this.textNoFollowingDeparture(response.textNoFollowingDeparture);
          this.textCa(response.textForecastQualityTimetable);
          this.textNow(response.textNow);
          this.textFailedGpsLocation(response.textFailedGpsLocation);
          this.textAriaLabelGps(response.textAriaLabelGps);
          this.textAriaLabelSearch(response.textAriaLabelSearch);
          this.textAriaOr(response.textAriaOr);
          this.textPleaseDefineYourSearch(response.textPleaseDefineYourSearch);

          this.model.bootstrap.profileLoaded = true;
          this.model.bootstrap.onUpdate();

          if (
            Array.isArray(response.googleAnalytics) &&
            response.googleAnalytics.length > 0
          ) {
            (window as any).gtag("js", new Date());

            for (var i = 0; i < response.googleAnalytics.length; i++) {
              (window as any).gtag(
                "config",
                response.googleAnalytics[i].trackingId
              );
            }

            var head =
              document.head || document.getElementsByTagName("head")[0];
            const script = document.createElement("script");
            script.async = true;
            script.src =
              "https://www.googletagmanager.com/gtag/js?id=" +
              response.googleAnalytics[0].trackingId;
            head.appendChild(script);
          }
        } else {
          console.log("Failed to get GetConfigOptions.");
        }
      },
      error: function (response) {
        console.log("Failed to get GetConfigOptions.");
      },
    }).done(() => this.initAfterSetConfigOptions());
  }

  initAfterSetConfigOptions() {
    this.model.timeFormatter = new ForecastTimeFormatter(
      this.model.config,
      this.model
    );
    // this.mqListMedium.addListener(() => this.callresize());
    // this.mqListLarge.addListener(() => this.callresize());

    $(window).bind("resize", () => {
      this.callresize();
    });

    this.callresize();

    this.getSystemDateTime();
  }

  getSystemDateTime() {
    $.ajax({
      type: "GET",
      contentType: "application/json; charset=utf-8",
      url: this.model.config.api.baseUri + "api/GetSystemTimestamp",
      data: { clientTimestamp: moment().format() },
      dataType: "json",
      success: (response) => {
        if (response) {
          var now = moment();

          var cdt = moment(response.ClientDateTime);
          var sdt = moment(response.SystemDateTime);

          var elapsedMilliSeconds = now.valueOf() - cdt.valueOf();

          sdt = moment(sdt.valueOf() - Math.floor(elapsedMilliSeconds / 2));
          this.systemDateTimeOffset = sdt.valueOf() - now.valueOf();

          console.log("systemDateTimeOffset: %d ms", this.systemDateTimeOffset);
        } else {
          console.log("Failed to get GetSystemDateTime.");
        }
      },
      error: function (response) {
        console.log("Failed to get GetSystemDateTime.");
      },
    }).done(() => this.initAfterSyncWithSystemDateTime());
  }

  initAfterSyncWithSystemDateTime() {
    this.tick(this.getNow());
    window.setInterval(() => {
      this.tick(this.getNow());
    }, 1000);

    $("#GpsButton").on("click", () => {
      if (navigator.geolocation) {
        if (!this.toggleSearchMap()) {
          return;
        }
        navigator.geolocation.getCurrentPosition(
          this.gpsLocation,
          this.gpsLocationError
        );
      } else {
        alert(this.textFailedGpsLocation());
      }
    });

    $("#SubmitStopButton").on("click", () => {
      $("#results").attr("aria-busy", "true");
      let searchParameters = new SearchParameters();
      searchParameters.fromStopAreaQuery($("#StopName").val().toString());
      this.search(searchParameters);
      $("#results").attr("aria-busy", "false");
    });

    $("#SubmitRDSButton").on("click", () => {
      $("#results").attr("aria-busy", "true");
      if (
        this.selectedStopPoint() &&
        this.selectedLine() &&
        (!this.useDirection() || this.selectedDirection())
      ) {
        let searchParameters = new SearchParameters();
        searchParameters.fromStopAreaQuery(this.selectedStopPoint().text());
        searchParameters.lineId(this.selectedLine().id());
        searchParameters.directionId(
          this.useDirection() ? this.selectedDirection().id() : 0
        );
        this.search(searchParameters);
      }
      $("#results").attr("aria-busy", "false");
    });

    $("#StopName").autocomplete({
      ajaxSettings: {
        dataType: "json",
        contentType: "application/json; charset=utf-8",
      },
      triggerSelectOnValidInput: false,
      serviceUrl: this.model.config.api.baseUri + "api/Autocomplete",
      transformResult: function (response) {
        return {
          suggestions: response,
        };
      },
      beforeRender: (container, suggestions) => {
        this.model.viewModel.ariaHasSuggestionPopup(true);
      },
      onHide: (container) => {
        this.model.viewModel.ariaHasSuggestionPopup(false);
      },
      onSelect: (suggestion) => {
        let searchParameters = new SearchParameters();
        searchParameters.fromStopAreaQuery(suggestion.value);
        this.search(searchParameters);
      },
    });

    this.respondToUrl();

    if (!this.hideRouteStopSelection()) {
      this.populateRoutesDDL();
    }

    hideSpinner();
  }
}

enum SizeMode {
  Normal,
  Small,
  ExtraSmall,
}

function toggleResultRow(event: KeyboardEvent) {
  let toggle = false;
  if (event.key == "Enter") {
    toggle = true;
  } else if (event.keyCode === 13) {
    toggle = true;
  }

  if (toggle) {
    (<any>event.target).click();
  }
}

const _global = (window /* browser */ || global) /* node */ as any;
_global.toggleResultRow = toggleResultRow;

function param(name: string): string {
  return (location.search.split(name + "=")[1] || "").split("&")[0];
}

function showSpinner() {
  $("#spinner").show();
}

function hideSpinner() {
  setTimeout(function () {
    $("#spinner").hide();
  }, 1000);
}

// Startup
export function InitAnyRide() {
  const model = new Model();
  window["model"] = model;
  model.config = new Configuration();
  model.bootstrap = new Bootstrap();
  model.resourceModel = new ResourceModel();
  model.translationModel = new TranslationModel();
  model.viewModel = new ViewModel(model);

  $.ajaxSetup({ cache: false });

  $.getJSON("app.config.json")
    .done((config) => {
      let apiUri = config.apiUri;
      apiUri = apiUri
        .replace("{protocol}", window.location.protocol)
        .replace("{host}", window.location.hostname);
      apiUri = apiUri + (apiUri.charAt(apiUri.length - 1) === "/" ? "" : "/");
      model.config.api.baseUri = apiUri;
      model.bootstrap.configLoaded = true;
      model.bootstrap.onUpdate();
    })
    .fail(() => {
      alert("config error");
    });

  $(document).ready(function () {
    model.bootstrap.pageLoaded = true;
    model.bootstrap.onUpdate();
  });

  model.bootstrap.addOnUpdate(function (b) {
    if (b.pageLoaded && b.profileLoaded && b.themeLoaded) {
      $("#splash").hide();
      return true;
    }
    return false;
  });

  model.bootstrap.addOnUpdate(function (b) {
    if (b.configLoaded && b.pageLoaded) {
      model.viewModel.start();
      return true;
    }
    return false;
  });
}
